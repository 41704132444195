import React, { useState, useEffect } from 'react';
import { TextField, Button, Select, MenuItem, FormControl, InputLabel, Box, Grid, IconButton, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { Container, AttributeContainer, TextFieldCustom, FormDiv, ButtonCust } from "../styles/Product.style";
import CircularProgress from '@mui/material/CircularProgress';
import DeleteIcon from '@mui/icons-material/Delete';
import { BUTTON_STYLE, HEADING_COLOR, CANCEL_BUTTON_STYLE } from "../../../../constants/style"
import {
  get_categories, get_companies, get_sub_categories, update_product,
  create_product, product_detail, get_units, get_gst_rates, api_product_add_attribute, api_productNamevalidation
} from '../services/product';
import { type Category, type MainAttributes } from "../interface/index"
import { useNavigate, useParams } from 'react-router-dom';
import ImageIcon from '@mui/icons-material/Image';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import {
  API_BASE_URL, IMG_BASE_URL, defaultCategory, defaultSubCategory, defaultUnit,
  STORE_ID, insertProductAttriCount
} from "../../../../constants/index";
import { get_product_search, get_product_search_with_quotation } from "../../order/services/order";

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});
const ProductForm = ({ setSelectedProductDetails, setDialogOpen, quotationId }: any) => {
  const { productId } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [companiesCode, setCompaniesCode] = useState<any>([]);
  const [product, setProduct] = useState<any>({
    name: '',
    categoryId: !productId ? defaultCategory : "",
    subCategoryId: !productId ? defaultSubCategory : "",
    description: '',
    storeId: '',
    attributes: [
      {
        companyId: "",
        price: '',
        discount: '0',
        landingPrice: "",
        productUnitCode: "",
        finalPrice: '',
        unitId: defaultUnit,
        companyProductCode: "",
        gst_percentage: 0,
        qty: '',
        images: []
      },
    ],

  });
  const [categories, setCategories] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [baseUnits, setBaseUnits] = useState([]);
  const [gstRates, setGstRates] = useState([]);
  const [error, setError] = useState("");
  const [subCategories, setSubCategories] = useState(
    !productId ? [{
      "id": defaultSubCategory,
      name: "None"
    }] : []);
  useEffect(() => {
    fetch_categories();
    fetch_companies();
    fetch_units();
    fetchGstRates();
  }, []);
  useEffect(() => {
    if (productId && Number(productId) > 0) {
      fetch_product_detail(Number(productId))
    }
  }, [productId]);
  const fetchGstRates = async () => {
    try {
      const response: any = await get_gst_rates();
      setGstRates(response.data?.data)
    } catch (error) {

    }
  }
  const fetch_product_detail = async (productId: number) => {
    try {
      const responseCompany: any = await get_companies();
      let companiesCCode = responseCompany.data.data;
      const response: any = await product_detail(productId);
      let productData = response.data;
      setProduct(response.data)
      fetch_sub_categories(response.data?.categoryId)
      if (productData && productData.attributes && productData.attributes.length > 0) {

        await Promise.all(productData.attributes.map(async (attribute: any, i: number) => {
          if (companiesCCode && companiesCCode.length > 0) {
            companiesCCode.map((e: any) => {
              if (e.id == attribute.companyId && !e.productCode) {
                e.productCode = attribute.companyProductCode
              }
              return e
            })
          }

          productData.attributes[i].images = [];
          if (attribute.image1) {
            productData.attributes[i].images.push(attribute.image1)
          }
          if (attribute.image2) {
            productData.attributes[i].images.push(attribute.image2)
          }
          if (attribute.image3) {
            productData.attributes[i].images.push(attribute.image3)
          }
          if (attribute.image4) {
            productData.attributes[i].images.push(attribute.image4)
          }

        }));
        setCompaniesCode([...companiesCCode])
      }

    } catch (error) {
    }
  }
  const fetch_categories = async () => {
    try {
      const response: any = await get_categories();
      setCategories(response.data.data)
    } catch (error) {
    }
  }
  const fetch_companies = async () => {
    try {
      const response: any = await get_companies();
      setCompanies(response.data.data)
      setCompaniesCode(response.data.data)
    } catch (error) {
    }
  }
  const fetch_units = async () => {
    try {
      const response: any = await get_units();
      setBaseUnits(response.data.data)
    } catch (error) {
    }
  }

  const fetch_sub_categories = async (subCategoryId: number) => {
    try {
      const response: any = await get_sub_categories(subCategoryId);
      setSubCategories(response.data.data)
    } catch (error) {
    }
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;

    if (name === "categoryId" && value && Number(value) > 0) {

      setProduct((prevState: any) => ({
        ...prevState,
        [name]: value,
        "subCategoryId": ''
      }));
      fetch_sub_categories(Number(value))
    } else {
      setProduct((prevState: any) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleAttributeChange = async (index: number, e: any) => {
    const { name, value } = e.target;
    const updatedAttributes = await Promise.all(product.attributes.map(async (attribute: any, i: number) => {
      if (name === 'companyProductCode') {
        attribute.companyProductCode = value;
      }

      if (i === index) {
        if (name === 'companyId' && companiesCode && companiesCode.length > 0) {
          let code = companiesCode.find((e: any) => e.id == value);
          if (code.productCode) {
            attribute.companyProductCode = code.productCode;
          }
        }
        let updatedAttribute = { ...attribute, [name as string]: value };
        if (name === 'price' || name === 'discount') {
          const price = parseFloat(updatedAttribute.price) || 0;
          const discount = parseFloat(updatedAttribute.discount) || 0;
          updatedAttribute.finalPrice = (price - (price * discount) / 100).toFixed(2);
        }
        return updatedAttribute;
      }
      return attribute;
    }));

    setProduct({ ...product, attributes: updatedAttributes });
  };

  const addAttribute = () => {
    setProduct((prevState: any) => ({
      ...prevState,
      attributes: [
        ...prevState.attributes,
        {
          companyId: '0',
          price: '',
          landingPrice: "",
          productUnitCode: "",
          discount: '0',
          finalPrice: '',
          qty: '',
          companyProductCode: '',
          unitId: defaultUnit,
          gst_percentage: 0,
          images: [],
        },
      ],
    }));

  };

  const removeAttribute = (index: number) => {
    setProduct((prevState: any) => ({
      ...prevState,
      attributes: prevState.attributes.filter((_: any, i: any) => i !== index),
    }));
  };

  const fileUploads = async () => {
    let productData = product;
    if (product.attributes) {

      await Promise.all(product.attributes.map(async (attr: any, index1: any) => {
        if (attr.images && attr.images.length > 0) {
          await Promise.all(attr.images.map(async (img: any, index: any) => {
            if (img instanceof File || img instanceof Blob) {
              let formData = new FormData();
              formData.append('image', img);
              try {
                // const response = await file_Upload(formData);
                const response = await fetch(`${API_BASE_URL}upload`, {
                  method: 'POST',
                  body: formData,
                  headers: {
                    'Authorization': `Bearer ${localStorage.getItem("token")}`, // Add Bearer token here
                  },
                });
                // Check if the response was successful
                if (response.ok) {
                  // Parse the response as JSON
                  const responseData = await response.json();
                  productData.attributes[index1].images[index] = responseData.image;
                } else {
                  // Handle errors if the response was not successful
                  // console.error("Error:", response.statusText);
                }
              } catch (error) {
                // Handle any errors that occurred during the fetch
                // console.error("Fetch error:", error);
              }

            }
          }))
        }
      }))

    }
    return productData;
  }
  const handleSubmit = async () => {
    const { name, categoryId, subCategoryId, attributes } = product;

    if (!name || !categoryId || !subCategoryId) {
      setErrorMessage("Please fill out all fields.")
      return;
    }

    for (const attribute of attributes) {
      if (!(attribute.companyId > 0) || !(attribute.unitId > 0) || !(attribute.price > 0) || !(attribute.finalPrice > 0)
        || !(attribute.qty == 0 || Number(attribute.qty) >= 0)
        || !(attribute.discount == 0 || Number(attribute.discount) >= 0) || !attribute.companyProductCode) {
        setErrorMessage("Please fill out all attribute fields.")
        return;
      }
    }
    const seenAttributes = new Set();

    for (const attribute of attributes) {
      // Create a unique key based on attributeId, qty, companyId, and unitId
      const attributeKey = `${attribute.qty}-${attribute.companyId}-${attribute.unitId}`;
      // Check for duplicate attributeKey
      if (seenAttributes.has(attributeKey)) {
        setErrorMessage(`Please remove duplicate attributes`);
        return;
      }
      // Add the attributeKey to the set for future duplicate checking
      seenAttributes.add(attributeKey);
    }

    setErrorMessage("");
    // Submit logic

    try {
      setLoading(true);

      product.storeId = Number(STORE_ID);
      if (productId && Number(productId) > 0) {
        let productData = await fileUploads();

        await update_product(productData, Number(productId))
        setLoading(false);
        navigate("/admin/product_list")
      } else {
        let productData = await fileUploads();

        let chunkedProductArray = Array.from(
          { length: Math.ceil(productData.attributes.length / insertProductAttriCount) },
          (_, i) => productData.attributes.slice(i * insertProductAttriCount, i * insertProductAttriCount + insertProductAttriCount)
        );
        let productId = "";
        let index = 0;
        for (const product of chunkedProductArray) {
          if (index === 0) {
            let payload = {
              name: productData.name,
              categoryId: productData.categoryId,
              subCategoryId: productData.subCategoryId,
              description: productData.description,
              storeId: productData.storeId,
              attributes: product,
            };
            const result: any = await create_product(payload);
            productId = result?.data?.productId;
          } else {
            if (productId && Number(productId) > 0) {
              let payload = {
                attributes: product
              }
              await api_product_add_attribute(payload, productId);
            }
          }
          index++;
        }

        // await create_product(productData)
        setLoading(false);
        if (setSelectedProductDetails) {
          let response: any;
          if (quotationId) {
            response = await get_product_search_with_quotation("", quotationId, "", productId);
          } else {
            response = await get_product_search("", "", productId);
          }

          let newProduct = response.data?.data?.length ? response.data?.data[0] : {};

          setSelectedProductDetails({ ...newProduct })
          setDialogOpen(false)
        } else {
          navigate("/admin/product_list")
        }

      }


    } catch (error) {
      setLoading(false);
    }

  };
  const deleteImage = (index: number, imageIndex: number) => {
    const productData = product;
    productData.attributes[index].images.splice(imageIndex, 1);
    setProduct({ ...productData });
  }
  const setCompanyArrayProductCode = (id: any, value: any) => {
    let newData = [...companiesCode];
    newData.map((e) => {
      if (e.id == id) {
        e.productCode = value
      }
      return e
    })
    setCompaniesCode(newData)

  }
  const setCompanyArrayAttriProductCode = (id: any, value: any) => {
    let newProduct: any = { ...product };
    if (newProduct.attributes && newProduct.attributes.length > 0) {
      newProduct.attributes.map((e: any) => {
        if (e.companyId == id) {
          e.companyProductCode = value
        }
        return e
      })
    }
    setProduct(newProduct)
  }
  const handleBlur = async () => {
    // console.log("Product Object:", product);
    if (!product?.name) {
      // console.warn("Product name is empty or undefined!");
      return;
    }
    try {
      const response: any = await api_productNamevalidation(product.name); 
      // console.log("API Response:", response); 
      if (response.data.isDuplicate === true) {
        setError("This Product already exists"); 
        setProduct((prevState: any) => ({
          ...prevState,
          name: "",
        }));
      } else {
        setError(""); 
      }
    } 
    catch (error) {
      // console.error("Validation Error:", error);
      // setError("Product is not duplicate"); 
      setError(""); 
    } 
  };
  
  
  return (
    <Container>
      <FormDiv>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h6">Create Product</Typography>
        </Box><br />
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6}>
            <TextFieldCustom
              label={
                <>
                  Product Name<span className="MuiInputLabel-asterisk">*</span>
                </>
              }
              name="name"
              value={product.name}
              onChange={handleInputChange}
              onBlur={handleBlur} // Call API on blur
              fullWidth
              size="small"
              error={!!error} // Show error state
              helperText={error} // Display error message
            />
          </Grid>
          <Grid item xs={12} sm={12} md={3}>
            <FormControl fullWidth size="small">
              <InputLabel>
                Category <span style={{ color: 'red' }}>*</span>
              </InputLabel>
              <Select
                name="categoryId"
                label="Category *"
                value={product.categoryId}
                onChange={handleInputChange as any}
                fullWidth
              >{
                  categories && categories.length > 0 && (
                    categories.map((e: Category, index: number) => {
                      return (
                        <MenuItem value={e.id} key={"category" + index}>{e.name}</MenuItem>
                      )
                    })
                  )
                }
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={3}>
            <FormControl fullWidth size="small">
              <InputLabel>
                Sub-Category <span style={{ color: 'red' }}>*</span>
              </InputLabel>
              <Select
                name="subCategoryId"
                label="Sub-Category *"
                value={product.subCategoryId}
                onChange={handleInputChange as any}
                fullWidth
              >
                {
                  subCategories && subCategories.length > 0 && (
                    subCategories.map((e: Category, index: number) => {
                      return (
                        <MenuItem value={e.id} key={"subCategories" + index}>{e.name}</MenuItem>
                      )
                    })
                  )
                }

              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <TextField
          label="Description"
          name="description"
          value={product.description}
          onChange={handleInputChange}
          multiline
          rows={4}
          fullWidth
          sx={{ marginTop: 2 }}
          size="small"
        />
        <Grid container spacing={2} sx={{ marginTop: "0px" }}>
          {
            companiesCode && companiesCode.length > 0 ?
              companiesCode.map((e: any) => {
                return (
                  <Grid item xs={12} sm={12} md={3}>
                    <TextFieldCustom
                      InputLabelProps={{
                        shrink: e.productCode ? true : false, // Ensure label stays above the input when it has a value
                      }}
                      label={
                        <>
                          {e.name} Code <span className="MuiInputLabel-asterisk">*</span>
                        </>
                      }
                      name="name"
                      value={e.productCode}
                      onChange={(f) => setCompanyArrayProductCode(e.id, f.target.value)}
                      onBlur={(f) => {
                        setCompanyArrayAttriProductCode(e.id, f.target.value)
                      }}
                      fullWidth
                      size="small"
                    />
                  </Grid>
                )
              })
              : null
          }

        </Grid>
        {product.attributes.map((attribute: any, index: number) => (
          <AttributeContainer key={index}>
            <Grid container spacing={2}>
              <Grid item xs={6} sm={6} md={1}>
                <FormControl fullWidth size="small">
                  <InputLabel>
                    Company <span style={{ color: 'red' }}>*</span>
                  </InputLabel>
                  <Select
                    name="companyId"
                    label="Company *"
                    value={attribute.companyId}
                    onChange={e => handleAttributeChange(index, e)}
                    fullWidth
                  >
                    {
                      companies && companies.length > 0 && (
                        companies.map((e: MainAttributes, index: number) => {
                          return (
                            <MenuItem value={e.id} key={"companies" + index}>{e.name}</MenuItem>
                          )
                        })
                      )
                    }

                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={5} sm={5} md={1}>
                <TextFieldCustom
                  label={
                    <>
                      Qty<span className="MuiInputLabel-asterisk">*</span>
                    </>
                  }
                  name="qty"
                  value={attribute.qty}
                  onChange={e => handleAttributeChange(index, e)}
                  fullWidth
                  size="small"
                  type="number"
                />
              </Grid>
              <Grid item xs={6} sm={6} md={1}>
                <FormControl fullWidth size="small">
                  <InputLabel>
                    Unit <span style={{ color: 'red' }}>*</span>
                  </InputLabel>
                  <Select
                    name="unitId"
                    label="Unit *"
                    value={attribute.unitId}
                    onChange={e => handleAttributeChange(index, e)}
                    fullWidth
                  >
                    {
                      baseUnits && baseUnits.length > 0 && (
                        baseUnits.map((e: {
                          id: number;
                          unit_short_name: string;
                        }, index: number) => {
                          return (
                            <MenuItem value={e.id} key={"unit" + index}>{e.unit_short_name}</MenuItem>
                          )
                        })
                      )
                    }

                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6} sm={6} md={2}>
                <TextFieldCustom
                  label={
                    <>
                      Price<span className="MuiInputLabel-asterisk">*</span>
                    </>
                  }
                  name="price"
                  value={attribute.price}
                  onChange={e => handleAttributeChange(index, e)}
                  fullWidth
                  size="small"
                  type="number"
                />
              </Grid>
              <Grid item xs={6} sm={6} md={1}>
                <TextFieldCustom
                  label={
                    <>
                      Landing Price
                    </>
                  }
                  name="landingPrice"
                  value={attribute.landingPrice}
                  onChange={e => handleAttributeChange(index, e)}
                  fullWidth
                  size="small"
                  type="number"
                />
              </Grid>

              <Grid item xs={6} sm={6} md={1}>
                <TextFieldCustom
                  label={
                    <>
                      Discount<span className="MuiInputLabel-asterisk">*</span>
                    </>
                  }
                  name="discount"
                  value={attribute.discount}
                  onChange={e => handleAttributeChange(index, e)}
                  fullWidth
                  size="small"
                  type="number"
                />
              </Grid>
              <Grid item xs={5} sm={5} md={1}>
                <TextFieldCustom
                  label={
                    <>
                      Final Price <span className="MuiInputLabel-asterisk">*</span>
                    </>
                  }
                  name="finalPrice"
                  value={attribute.finalPrice}
                  onChange={e => handleAttributeChange(index, e)}
                  fullWidth
                  size="small"
                  type="number"
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>



              <Grid item xs={6} sm={6} md={1}>
                <FormControl fullWidth size="small">
                  <InputLabel>
                    GST %
                  </InputLabel>
                  <Select
                    name="gst_percentage"
                    label="GST %"
                    value={attribute.gst_percentage}
                    onChange={e => handleAttributeChange(index, e)}
                    fullWidth
                  >

                    {
                      gstRates && gstRates.length > 0 && (
                        gstRates.map((e: { id: number, rate: string }, indexG: number) => {
                          return (
                            <MenuItem value={e.rate} key={index + "gst" + indexG}>{e.rate}%</MenuItem>
                          )
                        })
                      )
                    }

                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6} sm={6} md={1}>
                <TextFieldCustom
                  label={
                    <>
                      Code
                    </>
                  }
                  name="companyProductCode"
                  value={attribute.companyProductCode}
                  onChange={e => handleAttributeChange(index, e)}
                  fullWidth
                  size="small"
                  disabled={true}
                />
              </Grid>
              <Grid item xs={6} sm={6} md={1}>
                <TextFieldCustom
                  label={
                    <>
                      Unit  Code
                    </>
                  }
                  name="productUnitCode"
                  value={attribute.productUnitCode}
                  onChange={e => handleAttributeChange(index, e)}
                  fullWidth
                  size="small"
                />
              </Grid>
              <Grid item xs={1} sm={1} md={1}>
                <Box display="flex" alignItems="center" justifyContent="center" height="100%">
                  <Button onClick={() => removeAttribute(index)} sx={{ minWidth: "40px" }}>
                    <DeleteIcon />
                  </Button>
                  {/* <Button onClick={() => {}} sx={{ minWidth: "40px"}}>
                    <ImageIcon />
                  </Button> */}

                  {/* <Button
                component="label"
                role={undefined}
                variant="contained"
                tabIndex={-1}
                sx={{ minWidth: "40px"}}>
               <ImageIcon />
                <VisuallyHiddenInput
                  type="file"
                  onChange={(event) => {
                    if(event.target.files && event.target.files[0]){
                      let file = event.target.files[0];
                      let productData =  product;

                      productData.attributes[index].images.push(file);
                      setProduct({...productData})
                    }
                  }}
                  multiple
                />
              </Button> */}
                </Box>
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <Grid container spacing={2}>
                  {

                    attribute.images && attribute.images.length > 0 ?
                      attribute.images.map((e: any, imageIndex: any) => {
                        return (

                          e ? <Grid item xs={6} sm={6} md={2}>
                            <div style={{ position: "relative" }}>
                              <img src={e instanceof File || e instanceof Blob ? URL.createObjectURL(e) :
                                `${IMG_BASE_URL}${e}`}
                                alt="Uploaded preview" style={{ width: "100px", height: "90px", borderRadius: "10px" }} />
                              <IconButton aria-label="delete" style={{ position: "absolute", left: "0px" }}
                                onClick={() => {
                                  deleteImage(index, imageIndex)
                                }} >
                                <RemoveCircleIcon />
                              </IconButton>
                            </div>
                          </Grid> : null

                        )
                      })
                      : null
                  }
                </Grid>
              </Grid>

            </Grid>
          </AttributeContainer>
        ))}
        <Box sx={{ textAlign: "center", marginBottom: "10px" }}>
          <label style={{ color: "red" }}>{errorMessage}</label>
        </Box>
        <Button onClick={addAttribute} variant="outlined" sx={{ marginRight: 2, color: HEADING_COLOR, borderColor: HEADING_COLOR }}>
          Add Attribute
        </Button>


        <ButtonCust
          onClick={handleSubmit} sx={BUTTON_STYLE}
          disabled={loading}>
          {loading && <CircularProgress size="20px" style={{ marginRight: "10px" }} />}
          Submit
        </ButtonCust> &nbsp;
        <ButtonCust disabled={loading}
          sx={CANCEL_BUTTON_STYLE} onClick={() => {
            setSelectedProductDetails ? setDialogOpen(false) :
              navigate('/admin/product_list');
          }}>
          Cancel
        </ButtonCust>
      </FormDiv>
    </Container>
  );
};
export default ProductForm;

