import axios from "axios";
import { restApi } from "../../../../constants/api";
export const get_categories = async () => {
  const response = await restApi("categories", "GET", {});
  return response;
};
export const get_companies = async () => {
  const response = await restApi("companies", "GET", {});
  return response;
};

export const get_sub_categories = async (subCategoryId: number) => {
  const response = await restApi(
    `sub_categories?subCategoryId=${subCategoryId}`,
    "GET",
    {}
  );
  return response;
};
export const get_attributes = async (mainAttributesId: number) => {
  const response = await restApi(
    `attributes?mainAttributesId=${mainAttributesId}`,
    "GET",
    {}
  );
  return response;
};

export const create_product = async (payload: any) => {
  const response = await restApi(`product`, "POST", payload);
  return response;
};

export const product_list = async (
  page: number,
  limit: number,
  search?: string
) => {
  let finalSearch = search && search !== "" ? search : "";
  const response = await restApi(`product?page=${page}&limit=${limit}`, "GET", {
    search: finalSearch,
  });
  return response;
};

export const product_detail = async (productId: number) => {
  const response = await restApi(`product/${productId}`, "GET");
  return response;
};

export const update_product = async (payload: any, productId: number) => {
  const response = await restApi(`product/${productId}`, "PATCH", payload);
  return response;
};

export const get_all_sub_attributes = async () => {
  const response = await restApi(`attributes?isAll=true`, "GET");
  return response;
};

export const product_list_total = async (search: string = "") => {
  let finalSearch = search && search !== "" ? search : "";
  const response = await restApi(`product/total`, "GET", {
    search: finalSearch,
  });
  return response;
};

export const get_racks = async () => {
  const response = await restApi("racks", "GET", {});
  return response;
};

export const get_units = async () => {
  const response = await restApi("units/conversions", "GET", {});
  return response;
};

export const get_gst_rates = async () => {
  const response = await restApi(`/gst`, "GET");
  return response;
};

export const api_product_import = async (payload: any) => {
  const response = await restApi(`/product/import`, "POST", payload);
  return response;
};

export const api_product_add_attribute = async (payload: any, productId: any) => {
  const response = await restApi(
    `/product/add_attribute/${productId}`,"POST",payload );
  return response;
};

export const api_productNamevalidation = async (name: string) => {
    const response = await restApi(`/product/checkduplicate`, "POST", { name: name });
    return response;
  };
  
