import React from 'react';
import PersistentDrawerLeft from '../../../reusable/admin/Main';
import { Form, Input, Button, message, Row, Col } from "antd";
import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { BUTTON_STYLE } from "../../../../constants/style";
import { FormDiv, ButtonCust } from "../styles/Product.style";
import { create_users } from '../services/users';


const User = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);

  const onFinish = async (values: any) => {
    try {
      setLoading(true);
      const response = await create_users({
        fullName: values.fullName,
        mobileNo: values.mobileNo,
        email: values.email,
        GSTnumber: values.GSTnumber,
        addressInfo: values.addressInfo
      });
      message.success(`Users created successfully`);
      navigate(`/admin/user_list`);
      form.resetFields();
    } catch (error: any) {
      // console.error("Error creating department:", error);
      const errorMessage = error.response?.data?.message || "Failed to create department. Please try again.";
      message.error(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    // message.error("Failed to submit form. Please check your input.");
    console.log("Failed:", errorInfo);
  };

  const handleCreate = () => {
    navigate(`/admin/user_list`);
  };

  return (
    <FormDiv>
      <Box sx={{ display: "flex", justifyContent: "space-between", marginBottom: 2 }}>
        <Typography variant="h6">User Information</Typography>
        <ButtonCust onClick={handleCreate} sx={BUTTON_STYLE}>
          User List
        </ButtonCust>
      </Box>

      <Form
        form={form}
        name="userForm"
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Row gutter={16}>
          {/* Full Name Field */}
          <Col sm={24} md={12} lg={8} xl={8}>
            <Form.Item
              label="Full Name"
              name="fullName"
              rules={[{ required: true, message: "Please input your full name!" }]}
            >
              <Input placeholder="Enter your full name" />
            </Form.Item>
          </Col>
          {/* Mobile No Field */}
          <Col sm={24} md={12} lg={8} xl={8}>
            <Form.Item
              label="Mobile No"
              name="mobileNo"
              rules={[
                { required: true, message: "Please input your mobile number!" },
                { pattern: /^[0-9]{10}$/, message: "Please input a valid mobile number!" },
              ]}
            >
              <Input placeholder="Enter your mobile number" />
            </Form.Item>
          </Col>

          {/* Email Field */}
          <Col sm={24} md={12} lg={8} xl={8}>
            <Form.Item
              label="Email"
              name="email"
              // rules={[
              //   { required: true, message: "Please input your email!" },
              //   { type: 'email', message: "Please input a valid email!" },
              // ]}
            >
              <Input placeholder="Enter your email" />
            </Form.Item>
          </Col>
          {/* GST No Field */}
          <Col sm={24} md={12} lg={8} xl={8}>
            <Form.Item
              label="GST No"
              name="GSTnumber"
            // rules={[
            //   { required: true, message: "Please input your GST number!" },
            //   { pattern: /^[A-Za-z0-9]{15}$/, message: "Please input a valid GST number!" },
            // ]}
            >
              <Input placeholder="Enter your GST number" />
            </Form.Item>
          </Col>
          {/* Address Field */}
          <Col sm={24} md={12} lg={16} xl={16}>
            <Form.Item
              label="Address Info"
              name="addressInfo"
            // rules={[{ required: true, message: "Please input your address!" }]}
            >
              <Input.TextArea placeholder="Enter your address" rows={5} />
            </Form.Item>
          </Col>

          {/* Submit Button */}
          <Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </FormDiv>
  );
};

export default User;
