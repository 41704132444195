import React, { useState, useEffect, useRef } from 'react';
import ReactToPrint from 'react-to-print';
import {
  Container, Grid, Paper, Typography, Table, TableBody, TableCell, TableContainer, TableHead,
  Select, MenuItem, TableRow, Button, InputLabel, FormControl, Box
} from '@mui/material';
import { borderTop, styled } from '@mui/system';
import { useParams } from 'react-router-dom';
import { order_detail } from '../services/order';
import * as XLSX from 'xlsx-js-style';
import UniqueBiologicalHeader from '../../../reusable/UniqueBiologicalHeader';
import CompanyDepartmentDropdown from '../../../reusable/CompanyDepartmentDropdown';
import Termsconditions from '../../../reusable/Termsconditions';
import { Link } from 'react-router-dom';
import { WidthFull } from '@mui/icons-material';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { Col, Row } from 'antd';
import SohamHeader from '../../../reusable/SohamHeader';
import OrderViewOnetable from "./OrderViewOneTable";
import OrderViewTwoTable from './OrderViewTwoTable';
import PrintIcon from '@mui/icons-material/Print';
import DownloadIcon from '@mui/icons-material/Download';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const StyledContainer = styled(Container)({
  marginTop: 20,
  paddingBottom: 20,
});

const StyledHeader = styled(Paper)({
  padding: 20,
  marginBottom: 20,
  textAlign: 'center',
});

const StyledTableContainer = styled(TableContainer)({
  marginTop: 20,
});

const StyledButton = styled(Button)({
  marginTop: 20,
  backgroundColor: 'rgb(100, 113, 60)',
  color: '#fff',
});

const OrderDetailPage = () => {
  const [openInvoiceDailog, setOpenInvoiceDailog] = React.useState(false);
  const [selectedDepartments, setSelectedDepartmentss] = useState<any>(null);
  const [departments, setDepartments] = useState([]);
  const [selectedCompany, setSelectedCompanyy] = useState<any>(null);
  const [selecteduitable, setSelecteduitablee] = useState<any>("UNIQUE FIRM");
  const [categories, setCategories] = useState([]);
  const [isDiscountShow, setIsDiscountShow] = useState(true);
  const [isGstShow, setGstShow] = useState(true);
  const { orderId } = useParams();
  const [order, setOrder] = useState<any>({});
  const [data, setData] = useState<any>([]);
  const [companies, setCompanies] = useState([]);
  const [orderProducts, setOrderProducts] = useState<any[]>([]);
  const [printexl, setprintexl] = useState<any[]>([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState({
    message: "",
    isError: false
  });
  const printRef = useRef<HTMLDivElement>(null); // ref for print section
  const printRef2 = useRef<HTMLDivElement>(null); // ref for print section

  useEffect(() => {
    if (orderId) {
      fetchOrderDetail();
    }
  }, [orderId]);
  const fetchOrderDetail = async () => {
    const response: any = await order_detail(orderId);
    setOrder(response?.data?.quotation || {});
    setData(response?.data?.quotationProducts)
    setCompanies(response?.data?.companies && response?.data?.companies.length > 0 ? response?.data?.companies : [])
    setCategories(response?.data?.categories && response?.data?.categories.length > 0 ? response?.data?.categories : [])
    setDepartments(response?.data?.departments && response?.data?.departments.length > 0 ?
      response?.data?.departments : [])
    const transformedData = response?.data?.quotationProducts?.map((item: any, index: any) => {
      return {
        id: index + 1,
        name: item.productName,
        attribute: {
          "id": item.id,
          "quotationId": item.quotationId,
          "userId": item.userId,
          "qty": item.qty,
          "productId": item.productId,
          "companyId": item.companyId,
          "price": item.price,
          "discount": item.discount,
          "discountValue": item.discountValue,
          "discountAmount": item.discountAmount,
          "gstAmount": item.gstAmount,
          "totalPrice": item.totalPrice,
          "createdDttm": item.createdDttm,
          "gst_percentage": item.gst_percentage,
          "companyProductCode": item.companyProductCode,
          "gstValue": item.gstValue,
          "quotationQty": item.quotationQty,
          "productName": item.productName,
          "subCategoryName": item.subCategoryName,
          "categoryName": item.categoryName,
          "companyName": item.companyName,
          "unitName": item.unitName,
          "description": item.description,
          "productUnitCode": item.productUnitCode,
          "departmentId": item.departmentId,
          "categoryId": item.categoryId

        },
        quotationQty: item.quotationQty
      }
    }) || [];
    setOrderProducts([...transformedData]);
  };
  // Helper function to find the rate, discount, and final amount
  const findRates = (item: any, companyName: any) => ({
    [`${companyName}Rate`]: item.price,
    [`${companyName}DiscountPercent`]: item.discount,
    [`${companyName}DiscountAmt`]: item.discountAmount,
    [`${companyName}Gst`]: item.gst_percentage, // Using gst_percentage
    [`${companyName}Qty`]: item.quotationQty,
    [`${companyName}FinalAmt`]: item.totalPrice,
  });

  function transformData(data: any) {
    const result: any = [];

    // Grouping data by productId, unitName, and qty
    const groupedData = data.reduce((acc: any, item: any) => {
      const key = `${item.productName}-${item.unitName}-${item.qty}`;
      if (!acc[key]) {
        acc[key] = {
          id: item.productId,
          productName: item.productName,
          description: item.description,
          pack: `${item.qty} ${item.unitName}`,
          gst: item.gst_percentage, // Assuming GST percentage is the same for all companies
          ...findRates(item, item.companyName.toLowerCase())
        };
      } else {
        Object.assign(acc[key], findRates(item, item.companyName.toLowerCase()));
      }
      return acc;
    }, {});

    // Convert grouped data to the result array
    Object.values(groupedData).forEach((item: any, index: any) => {
      result.push({
        id: index + 1,
        ...item
      });
    });

    return result;
  }


  function generateExcel(dataa: any) {
    let finalData = dataa;
    if (selectedCompany && selectedCompany.length > 0) {
      finalData = dataa.filter((product: any) =>
        selectedCompany.includes(product.companyId)
      )
    }
    let data = transformData(finalData);
    setprintexl(data);
    let finalCompanies = companies;
    if (selectedCompany && selectedCompany.length > 0) {
      finalCompanies = companies.filter((c: any) =>
        selectedCompany.includes(c.id)
      )
    }
    const mainHeader = selecteduitable === 'SOHAM FIRM' ?
      "Soham Chemicals" :
      " UNIQUE BIOLOGICAL & CHEMICALS";
    // const logoPath = 'https://i.pinimg.com/736x/da/c7/58/dac758d7606690d057a9cbba5f259154.jpg';
    const addressLine = selecteduitable === 'SOHAM FIRM' ? "📍 1330, Plot No.190, Jivhala Colony, Kolhapur - 416 010" : " 📍 726/A, 'E' Ward, Sai Plaza, 3rd Lane, Shahupuri, Kolhapur - 416001."
    const contactLine1 = selecteduitable === 'SOHAM FIRM' ? "📞 9730283030 / 8686648282," : "📞 0231-2661216, 📲 8686648282, 9922805555"
    const contactLine2 = selecteduitable === 'SOHAM FIRM' ? "✉️ sohamsci2005@yahoo.com, GST : 27CHIPP7362K1Z0" : "📞 9730283030, ✉️ vinodunique@yahoo.com, GST: 27AANPP2410Q1ZJ"

    // Create the headers
    // const header1 = ["No", "Name of Chemicals", "", "Pack", "", "", "Rate", "", "", "Dis.%", "", "", "Dis. Amt", "", "", "GST", "", "", "Final Amount", "", ""];
    // const header2 = ["", "", "PCL", "RLCC", "LOBA", "PCL", "RLCC", "LOBA", "PCL", "RLCC", "LOBA", "PCL", "RLCC", "LOBA", "PCL", "RLCC", "LOBA", "PCL", "RLCC", "LOBA"];
    // Initialize the headers
    const header1 = ['No', 'Name of Chemicals'];
    const header2 = ['', ''];

    // Define sections based on the requirements
    const sections = ['Pack', 'Rate', 'Dis.%', 'Dis. Amt', 'GST',
      'Qty',
      'Final Amount'];
    function getCenterIndex(arr: any) {
      if (arr.length === 0) return -1; // handle empty array
      return Math.floor(arr.length / 2);
    }
    let arrayCenterIndex = getCenterIndex(finalCompanies);
    // Generate headers dynamically
    sections.forEach(section => {
      finalCompanies.forEach((company: any, index) => {
        if (arrayCenterIndex == index) {
          header1.push(section);
        } else {
          header1.push('');
        }

        header2.push(company.name);
      });
    });
    // Create rows array with bold headers
    const rows = [
      [mainHeader],             // Company Name Row
      // [logoPath],             // Company logo
      [addressLine],            // Address Row
      [contactLine1],           // Contact Line 1 Row
      [contactLine2],           // Contact Line 2 Row
      [],
      header1, header2];

    data.map((item: any, index: any) => {
      // Initialize the row with basic information (index and product name)
      const row = [index + 1, item.productName];

      // Loop through each company in the `companies` array to extract values
      companies.filter((c: any) =>
        selectedCompany ? (
          selectedCompany.includes(c.id)
        ) : true
      ).forEach((company: any) => {
        const companyKey = company.name.toLowerCase(); // Convert company name to lowercase
        row.push(item[`${companyKey}Rate`] ? item.pack : "-"); // Pack
      });

      companies.filter((c: any) =>
        selectedCompany ? (
          selectedCompany.includes(c.id)
        ) : true
      ).forEach((company: any) => {
        const companyKey = company.name.toLowerCase();
        row.push(item[`${companyKey}Rate`] || 0); // Rate
      });

      companies.filter((c: any) =>
        selectedCompany ? (
          selectedCompany.includes(c.id)
        ) : true
      ).forEach((company: any) => {
        const companyKey = company.name.toLowerCase();
        row.push(item[`${companyKey}DiscountPercent`] ? `${item[`${companyKey}DiscountPercent`]}%` : '0%'); // Discount Percent
      });

      companies.filter((c: any) =>
        selectedCompany ? (
          selectedCompany.includes(c.id)
        ) : true
      ).forEach((company: any) => {
        const companyKey = company.name.toLowerCase();
        row.push(item[`${companyKey}DiscountAmt`] || 0); // Discount Amount
      });

      companies.filter((c: any) =>
        selectedCompany ? (
          selectedCompany.includes(c.id)
        ) : true
      ).forEach((company: any) => {
        const companyKey = company.name.toLowerCase();
        row.push(item[`${companyKey}Gst`] ? `${item[`${companyKey}Gst`]}%` : '0%'); // GST
      });

      companies.filter((c: any) =>
        selectedCompany ? (
          selectedCompany.includes(c.id)
        ) : true
      ).forEach((company: any) => {
        const companyKey = company.name.toLowerCase();
        row.push(item[`${companyKey}Qty`] ? `${item[`${companyKey}Qty`]}` : '0'); // Qty
      });

      companies.filter((c: any) =>
        selectedCompany ? (
          selectedCompany.includes(c.id)
        ) : true
      ).forEach((company: any) => {
        const companyKey = company.name.toLowerCase();
        row.push(item[`${companyKey}FinalAmt`] || 0); // Final Amount
      });
      rows.push(row);
      return row;
    });


    // Create a new workbook and a new worksheet
    const ws = XLSX.utils.aoa_to_sheet(rows);

    // Apply bold styling to the header rows
    for (let i = 0; i < 7; i++) {
      for (let j = 0; j < header1.length; j++) {
        const cell = ws[XLSX.utils.encode_cell({ r: i, c: j })];
        if (cell && (i == 6 || i == 5 || i == 0)) {
          cell.s = {
            font: {
              bold: true
            }
          };
        }
      }
    }

    // Create a new workbook and add the worksheet
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Chemical Data');

    // Trigger download of the Excel file
    XLSX.writeFile(wb, 'chemical_data.xlsx');
  }

  const borderColor = selecteduitable === "SOHAM FIRM" ? "rgb(166, 166, 192)" : "rgb(175 193 123 / 49%)";
  const bgColor = selecteduitable === "SOHAM FIRM" ? "rgb(166, 166, 192)" : "rgb(175 193 123 / 78%)";

  const styles = {
    tableHead: {
      backgroundColor: bgColor,
    },
    sohamtableHead: {
      backgroundColor: bgColor,
    },
    tableCell: {
      color: "#2c2c2c",
      border: `1px solid ${borderColor}`,
      fontWeight: "bold",
      fontSize: "11px",
      padding: "6px",
    },
    tableCell2: {
      border: `1px solid ${borderColor}`,
      borderRight: "0px solid white",
      borderTop: `1px solid ${borderColor}`,
      fontSize: "11px",
      padding: "6px",
    },
    tableCell5: {
      border: `1px solid ${borderColor}`,
      borderTop: `1px solid ${borderColor}`,
      borderRight: `2px solid ${borderColor}`,
      fontSize: "11px",
      padding: "6px",
    },
    tableCell6: {
      border: `1px solid ${borderColor}`,
      borderTop: `1px solid ${borderColor}`,
      borderLeft: `2px solid ${borderColor}`,
      borderRight: "0px solid white",
      fontSize: "11px",
      padding: "6px",
    },
    tableCell7: {
      border: `1px solid ${borderColor}`,
      borderTop: `1px solid ${borderColor}`,
      borderLeft: `2px solid ${borderColor}`,
      borderRight: `1px solid ${borderColor}`,
      borderBottom: `2px solid ${borderColor}`,
      fontSize: "11px",
      padding: "6px",
    },
    tableCell8: {
      border: `1px solid ${borderColor}`,
      borderTop: `1px solid ${borderColor}`,
      borderLeft: "0px solid white",
      borderRight: `2px solid ${borderColor}`,
      borderBottom: `2px solid ${borderColor}`,
      fontSize: "11px",
      padding: "6px",
    },
    tableCell4: {
      border: `1px solid ${borderColor}`,
      fontSize: "16px",
      fontWeight: "bold",
      padding: "6px",
    },
    tableCell3: {
      width: "400px",
      padding: "6px",
    },
    tableCellexl: {
      width: "1000px",
    },
    tableRow: {
      border: `1px solid ${borderColor}`,
    },
    tablemain: {
      borderCollapse: "separate" as "collapse" | "separate",
      width: "100%",
      tableLayout: "auto" as "auto", // | "fixed",
    },
  };

  // Print styles defined separately
  const PrintStyles = styled('div')(({ theme }) => ({
    '@media print': {
      width: '100%',
      margin: '0 auto',
      padding: '0',
      fontSize: '11px',
      // Add any additional print-specific styles here
      '& table': {
        borderCollapse: 'collapse',
        width: '100%',
        tableLayout: 'fixed',
      },
      '& .product-code-cell': {
        width: '300px !important',
      },
    },
  }));
  const generateNewForm = (dataa: any) => {
    let finalData = dataa;
    if (selectedCompany && selectedCompany.length > 0) {
      finalData = dataa.filter((product: any) =>
        selectedCompany.includes(product.companyId)
      )
    }
    let data = transformData(finalData);
    setprintexl(data);
    setSnackbarOpen(true);
  }
  const columnGroups = [
    {
      label: "Pack", colSpan: companies && companies.length > 0 ? companies.filter((c: any) =>
        selectedCompany ? (
          selectedCompany.includes(c.id)
        ) : true
      ).length : 3
    },
    {
      label: "Rate", colSpan: companies && companies.length > 0 ? companies.filter((c: any) =>
        selectedCompany ? (
          selectedCompany.includes(c.id)
        ) : true
      ).length : 3
    },
    {
      label: "Dis. %", colSpan: companies && companies.length > 0 ? companies.filter((c: any) =>
        selectedCompany ? (
          selectedCompany.includes(c.id)
        ) : true
      ).length : 3
    },
    {
      label: "Dis. Amt", colSpan: companies && companies.length > 0 ? companies.filter((c: any) =>
        selectedCompany ? (
          selectedCompany.includes(c.id)
        ) : true
      ).length : 3
    },
    {
      label: "GST", colSpan: companies && companies.length > 0 ? companies.filter((c: any) =>
        selectedCompany ? (
          selectedCompany.includes(c.id)
        ) : true
      ).length : 3
    },
    {
      label: "Qty", colSpan: companies && companies.length > 0 ? companies.filter((c: any) =>
        selectedCompany ? (
          selectedCompany.includes(c.id)
        ) : true
      ).length : 3
    },
    {
      label: "Final Amount", colSpan: companies && companies.length > 0 ? companies.filter((c: any) =>
        selectedCompany ? (
          selectedCompany.includes(c.id)
        ) : true
      ).length : 3
    }
  ];

  const filteredProducts = (selectedCompany && selectedCompany.includes('All'))
    ? orderProducts
    : orderProducts.filter(product =>
      selectedCompany ? (
        selectedCompany.includes(product.attribute.companyId) ||
        selectedCompany.includes(product.attribute.totalPrice)
      ) : true
    );

  const filteredprintexl = printexl; // selectedCompany === 'ALL' ?  printexl : printexl.filter(product =>
  //   selectedCompany === null || selectedCompany === '' || product.attribute.companyName === selectedCompany
  // );
  const totalAmount = filteredProducts.reduce((acc, product) => acc + product.attribute.totalPrice, 0).toFixed(2);
  // const exltotalAmount = filteredprintexl.reduce((acc, product) => acc + product.pclFinalAmt + product.rlccFinalAmt + product.lobaFinalAmt, 0).toFixed(2);
  const roundedTotalAmount = parseFloat(totalAmount);
  // const exlroundedTotalAmount = parseFloat(exltotalAmount);


  return (
    <>
      <div style={{
        backgroundColor: selecteduitable === "UNIQUE FIRM" ? '#e5f1c3' : 'rgb(228 228 228)',
        padding: '20px'
      }}>
        <StyledContainer >
          <Container>
            <CompanyDepartmentDropdown
              isDiscountShow={isDiscountShow} setIsDiscountShow={setIsDiscountShow}
              isGstShow={isGstShow} setGstShow={setGstShow}
              companies={companies}
              setSelectedDepartmentss={setSelectedDepartmentss} setSelectedCompanyy={setSelectedCompanyy}
              setSelecteduitablee={setSelecteduitablee}
              departments={departments} />
            <Grid container justifyContent="center" mt={5}>
              <Link to="/admin/quotation_list">
                <StyledButton variant="contained" >
                  Quotation List
                </StyledButton>
              </Link>
              &nbsp;&nbsp;
              <ReactToPrint
                trigger={() => (
                  <StyledButton variant="contained" startIcon={<PrintIcon />}>
                    View-1 Print
                  </StyledButton>
                )}
                content={() => printRef.current}
                pageStyle={`
            @page {
              margin-top: 10mm; /* Set all margins to 0 for print */
              padding: 0mm; /* Set padding to 0 for print */
              page-break-after: always; /* Ensure page breaks are respected */
            }
            .MuiContainer-root {
              padding-left: 0 !important;
              padding-right: 0 !important;
            }
            .logo2{
              width: 363px!important;
              height: 91px!important;
            }
            .logo1 {
              width: 190px !important;
              height: 69px !important;
              margin-bottom: -11px !important;
            }
            @media print {
              .product-code-cell-code {
                display: none !important; /* Ensure it displays correctly in print */
              }
            }
              th.product-code-cell, td.product-code-cell{
                width:190px !important ;
                max-width: 190px !important;
                white-space: normal !important;
                overflow: hidden !important;
                text-overflow: ellipsis !important;
               
              }
          `}
              // th.product-code-cell, td.product-code-cell {
              //   width: 200px !important;
              //   max-width: 200px !important;
              //   min-width: 200px !important;
              //   word-break: break-word;
              // }
              // .header-logo {
              //   width: 10px;
              //   height: 10px;
              // }
              />
              &nbsp;&nbsp;
              <StyledButton startIcon={<DownloadIcon />} variant="contained" onClick={() => generateExcel(data)}>
                Download Quotation (Excel) View-2
              </StyledButton>
              &nbsp;&nbsp;
              <StyledButton variant="contained" onClick={() => generateNewForm(data)}>
                Create pdf View-2
              </StyledButton>
              &nbsp;&nbsp;
              <ReactToPrint
                trigger={() => (
                  <StyledButton variant="contained" startIcon={<PrintIcon />}>
                    View-2 Print
                  </StyledButton>
                )}
                content={() => printRef2.current}
                pageStyle={`
                        @page {
                          margin-top: 10mm; /* Set all margins to 0 for print */
                          padding: 0mm; /* Set padding to 0 for print */
                          page-break-after: always; /* Ensure page breaks are respected */
                        }
                        .MuiContainer-root {
                          padding-left: 0 !important;
                          padding-right: 0 !important;
                        }
                        
                        .logo2{
                          width: 307px!important;
                          height: 59px!important;
                        }
                        .logo1 {
                          width: 190px !important;
                          height: 69px !important;
                          margin-bottom: -11px !important;
                        }
                        .logo2{
                          width: 230px!important;
                          height: 59px!important;
                        }
                          th.product-code-cell, td.product-code-cell{
                          width:175px !important ;
                          max-width: 175px !important;
                          white-space: normal !important;
                          overflow: hidden !important;
                          text-overflow: ellipsis !important;
                        
                        }
                        @media print {
                          /* Hide overflow and scroll for print */
                          .product-code-cell-code {
                            display: table !important; /* Ensure table content shows */
                            overflow: hidden !important;
                            max-height: none !important;
                          }
                          /* Additional rule to remove scrollbars */
                          .product-code-cell-code::-webkit-scrollbar {
                            display: none;
                          }
                        }
                      `}
              // width: 230px !important;
              // height: 130px !important;
              />

            </Grid>
          </Container>
          <PrintStyles ref={printRef}>
            <div style={{ margin: "10px", marginLeft: "30px", marginBottom: "20px", marginTop: "20px" }}>
              {selecteduitable === 'UNIQUE FIRM' && (
                <UniqueBiologicalHeader width={100} selecteduitable={selecteduitable} order={order} />
              )}
              {selecteduitable === 'SOHAM FIRM' && (
                <SohamHeader width={100} selecteduitable={selecteduitable} order={order} />
              )}

              <StyledTableContainer>
                {
                  selectedDepartments && selectedDepartments.length > 0 &&
                  selectedDepartments.map((e: any) => {
                    const departmentt: any = departments.find((d: any) => {
                      console.log(`Checking department id: ${d.id}, looking for: ${e}`);
                      return d.id === e;
                    });
                     console.log("departmentt",departmentt)
                    return (
                      <>
                        <Row>
                          <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: 'center' }}>
                            <label style={{ textAlign: 'center', fontSize: '19px', fontWeight: 'bold' }}>{
                              departmentt?.name}   &nbsp;
                              {
                                ( departmentt?.deptEmpName || departmentt?.deptEmpMobileNo) &&
                                <span style={{ fontSize: "13px", fontWeight: "400" }}> 
                                ( {departmentt?.deptEmpName} {
                                  departmentt?.deptEmpMobileNo
                                } )
                                </span> 
                              }
                             

                            </label>
                          </Col>
                        </Row>
                        {
                          categories && categories.length > 0 && (
                            categories.map((c: any) => {
                              return (
                                [...filteredProducts.filter((f: any) =>
                                  f.attribute.departmentId == e && f.attribute.categoryId == c.id)].length > 0 &&
                                <>
                                  <Row>
                                    <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: 'left' }}>
                                      <label style={{ textAlign: 'left', fontSize: '12px', fontWeight: 'bold' }}>{
                                        c.name}</label>
                                    </Col>
                                  </Row>
                                  <OrderViewOnetable selecteduitable={selecteduitable}
                                    filteredProducts={[...filteredProducts.filter((f: any) =>
                                      f.attribute.departmentId == e && f.attribute.categoryId == c.id)]}
                                    roundedTotalAmount={roundedTotalAmount} isDiscountShow={isDiscountShow} isGstShow={isGstShow}
                                  />
                                </>
                              )
                            })

                          )
                        }
                      </>
                    )
                  })
                }
                {!selectedDepartments &&
                  categories && categories.length > 0 && (
                    categories.map((c: any) => {
                      return (
                        [...filteredProducts.filter((f: any) =>
                          f.attribute.categoryId == c.id)].length > 0 &&
                        <>
                          <Row>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: 'left' }}>
                              <label style={{ textAlign: 'left', fontSize: '12px', fontWeight: 'bold' }}>{
                                c.name}</label>
                            </Col>
                          </Row>

                          <OrderViewOnetable selecteduitable={selecteduitable}
                            filteredProducts={[...filteredProducts.filter((f: any) =>
                              f.attribute.categoryId == c.id)]}
                            roundedTotalAmount={roundedTotalAmount} isDiscountShow={isDiscountShow} isGstShow={isGstShow} />
                        </>
                      )
                    })

                  )
                }
              </StyledTableContainer>
              <Termsconditions
                order={order}
                style={selecteduitable === "SOHAM FIRM" ? styles.sohamtableHead : styles.tableHead}
                selecteduitable={selecteduitable} />

            </div>
          </PrintStyles>


          <br />
          <br />
          <br />
          <PrintStyles ref={printRef2}>
            <div style={{ margin: "10px", marginLeft: "40px", marginBottom: "20px", marginTop: "20px" }}>

              {!selecteduitable || selecteduitable === 'UNIQUE FIRM' && (
                <UniqueBiologicalHeader width={100} selecteduitable={selecteduitable} order={order} />
              )}
              {selecteduitable === 'SOHAM FIRM' && (
                <SohamHeader width={100} selecteduitable={selecteduitable} order={order} />
              )}
              <OrderViewTwoTable selecteduitable={selecteduitable} filteredprintexl={[...filteredprintexl]}
                companies={[...companies]} styles={styles} selectedCompany={selectedCompany}
                columnGroups={columnGroups} isDiscountShow={isDiscountShow} isGstShow={isGstShow}
              />
              <Termsconditions
                order={order}
                style={selecteduitable === "SOHAM FIRM" ? styles.sohamtableHead : styles.tableHead}
                selecteduitable={selecteduitable} />
            </div>
          </PrintStyles>
          <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)}>
            <Alert onClose={() => setSnackbarOpen(false)} severity="success">
              Data processed successfully!
            </Alert>
          </Snackbar>
        </StyledContainer>
      </div>
    </>
  );
};

export default OrderDetailPage;
